#influencer-header{
    color: #DEDEE0;
    text-align: center;
    font-size: 60px;
    padding-bottom: 50px;
}

.center{
    text-align: center;
}

#influencers{
    padding-top: 50px;
    padding-bottom: 80px;
}

#influencer-features{
    color: #DEDEE0;
    font-size: 30px;
}

#influencer-features{
    border-left: 2px solid #DEDEE0;
    list-style-type: none;
    margin-bottom: 0px;
    padding-left: 40px;
    border-left: 2px solid #DEDEE0;
}

.vertical-center{
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}

#influencer-image{
    width: 94%;
    transition: transform .3s;
}

#influencer-image:hover{
    transform: scale(1.05);
}

@media (max-width: 767px){
    #influencer-desc{
        flex-direction: column-reverse;
    }

    #influencer-features{
        font-size: 20px;
        margin-bottom: 30px !important;
    }
}
