#logo{
    margin-right: 0;
    color: #90949e;
    font-size: 40px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0px;
    flex: .5;
    display: flex;
    justify-content: center;
}

#toggler{
    position: absolute;
}

.nav-link{
    color: #90949e;
    font-size: 25px;
    font-family: Lato;
}

.navbar-toggler-icon{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(144, 148, 158, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.custom-toggler{
    border-color: rgb(144, 148, 158) !important;

}

.nav-links{
    text-align: right;
}

.nav-link:hover{
    color: #DEDEE0;
}

#nav-links-1{
    justify-content: flex-end !important;
}

@media (min-width: 768px){
    #nav-links-1{
        flex: 1;
        justify-content: right;
    }

    #nav-links-2{
        flex: 1;
        justify-content: left;
    }
}

@media (max-width: 767px){
    #logo{
        position: absolute;
        left: 16px;
        top: 0px;
        padding-left: 0px;
    }

    #toggler{
        flex-direction: row-reverse;
    }

    .navbar{
        flex-direction: row-reverse;
        height: 75px;
        line-height: 75px;
    }

    .nav-link{
        height: 50px;
        line-height: 34px;
        padding-right: 0px !important;
        font-size: 20px;
    }

    #nav-links-1{
        padding-top: 50px;
    }
}