#footer{
    background-color: #252323;
    height: 40px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

#footer-text{
    text-align: center;
    color: #DEDEE0;
    margin: auto;
    line-height: 40px;
}