#logo{
    margin-right: 0;
    color: #90949e;
    font-size: 40px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0px;
    flex: 1;
    display: flex;
    justify-content: center;
}