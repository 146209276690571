#header{
    padding-bottom: 50px;
}

.features{
    margin-bottom: -1px;
    background-color: #f2f2f2;
    text-align: center;
    color: #252323;
    padding-bottom: 80px;
    padding-top: 50px;
    transition: 2s;
}

#title{
    font-size: 60px;
}

.abstract-features{
    height: auto;
    width: 80%;
    padding-bottom: 30px;
}

.feature-heading{
    font-size: 35px;
    text-align: left;
}

.feature-desc{
    font-size: 20px;
    text-align: left;
}

.images{
    position: relative;
}

#analytics{
    position: absolute;
    height: 90%;
}

.feature-images{
    position: absolute;
    height: 90%;
}

#arrows{
    transform: rotate(-20deg);
}

.feature{
    transition: transform .5s;
}

.feature:hover{
    transform: scale(1.05);
    z-index: 1;
}

@media (max-width: 767px){
    .abstract-features{
        padding-bottom: 0px;
    }

    .feature-heading{
        text-align: center;
    }

    .feature-desc{
        padding-bottom: 50px;
        text-align: center;
    }
}