#contacts{
    padding-bottom: 80px;
    background-color: #f2f2f2;
    padding-top: 60px;
}

#contact-header{
    color: #DEDEE0;
    font-size: 60px;
    text-align: center;
    padding-bottom: 40px;
}

#contact-card{
    background-color: #252323;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    padding-top: 60px;
    padding-bottom: 60px;
}

.contact-image{
    width: 30%;
    color: #DEDEE0;
}

.contact-tab{
    text-align: center;
    transition: transform .3s;
}

.contact-tab:hover{
    text-decoration: none;
}

.contact-text{
    color: #DEDEE0;
    padding-top: 5px;
}

.contact-tab:hover{
    transform: scale(1.05);
}

@media (max-width: 767px){
    .contact-tab{
        padding-bottom: 10px;
    }
}