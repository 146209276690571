@import url(https://fonts.googleapis.com/css?family=Montserrat|Roboto|Lato);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html{
    overflow-x:hidden
}

body{
    background-color: #252323 !important;
}

h1,h4,h5,p{
    font-family: Montserrat !important;
}

h2,h3,li{
    font-family: Roboto !important;
}

.inverted{
    transform: rotate(180deg);
}

#transition-1{
    padding-top: 210px;
    margin-bottom: -2px;
    width: 100vw;
}

#transition-2{
    margin-top: -2px;
}

#transition-3{
    margin-bottom: -2px;
}

.full{
    width: 100vw;
}

@media (max-width: 767px){
    .order1{
        order: 1;
    }

    .order2{
        order: 2;
    }
}

.center{
    text-align: center;
}
#logo{
    margin-right: 0;
    color: #90949e;
    font-size: 40px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0px;
    flex: .5 1;
    display: flex;
    justify-content: center;
}

#toggler{
    position: absolute;
}

.nav-link{
    color: #90949e;
    font-size: 25px;
    font-family: Lato;
}

.navbar-toggler-icon{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(144, 148, 158, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.custom-toggler{
    border-color: rgb(144, 148, 158) !important;

}

.nav-links{
    text-align: right;
}

.nav-link:hover{
    color: #DEDEE0;
}

#nav-links-1{
    justify-content: flex-end !important;
}

@media (min-width: 768px){
    #nav-links-1{
        flex: 1 1;
        justify-content: right;
    }

    #nav-links-2{
        flex: 1 1;
        justify-content: left;
    }
}

@media (max-width: 767px){
    #logo{
        position: absolute;
        left: 16px;
        top: 0px;
        padding-left: 0px;
    }

    #toggler{
        flex-direction: row-reverse;
    }

    .navbar{
        flex-direction: row-reverse;
        height: 75px;
        line-height: 75px;
    }

    .nav-link{
        height: 50px;
        line-height: 34px;
        padding-right: 0px !important;
        font-size: 20px;
    }

    #nav-links-1{
        padding-top: 50px;
    }
}
.intro{
    text-align: center;
    padding-top: 210px;
}

#welcome{
    color: #DEDEE0;
    font-size: 60px;
}

#waitlist{
    background-color: #252323;
    color: #0ACDFF;
    font-size: 24px;
    border: 2px solid #0ACDFF;
    transition: .5s;
    font-family: Roboto;
}

#waitlist:hover{
    background-color: #0ACDFF;
    color: #252323;
}

#subtitle{
    color: #90949e;
    padding-bottom: 10px;
}

.square{
    opacity: 0;
    position: absolute;
    animation: fade;
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    z-index: -1;
}

#square1{
    width: 150px;
    left: 20%;
    top: 10%;
    transform: rotate(10deg);
}

#square2{
    width: 120px;
    right: 15%;
    top: 40%;
    transform: rotate(10deg);
    animation-delay: 2s;
}

#square3{
    width: 140px;
    left: 20%;
    top: 50%;
    transform: rotate(-24deg);
    animation-delay: 3s;
}

#square4{
    width: 100px;
    left: 35%;
    top: 45%;
    transform: rotate(14deg);
    animation-delay: 4s;
}

#square5{
    width: 140px;
    right: 25%;
    top: 15%;
    transform: rotate(-5deg);
    animation-delay: 1s;
}

#text{
    color: #DEDEE0;
    font-size: 20px;
}

@keyframes fade {
    20% {opacity: 0}
    80% {opacity: 1}
}

@media (max-width: 576px){
    #square5{
        visibility: hidden;
    }
}

@media (max-width: 767px){
    #square1{
        width: 120px;
    }
    #square2{
        width: 80px;
    }
    #square3{
        width: 90px;
    }
    #square5{
        width: 100px;
    }
}

@media (max-width: 992px){
    #square4{
        visibility: hidden;
    }
}
#header{
    padding-bottom: 50px;
}

.features{
    margin-bottom: -1px;
    background-color: #f2f2f2;
    text-align: center;
    color: #252323;
    padding-bottom: 80px;
    padding-top: 50px;
    transition: 2s;
}

#title{
    font-size: 60px;
}

.abstract-features{
    height: auto;
    width: 80%;
    padding-bottom: 30px;
}

.feature-heading{
    font-size: 35px;
    text-align: left;
}

.feature-desc{
    font-size: 20px;
    text-align: left;
}

.images{
    position: relative;
}

#analytics{
    position: absolute;
    height: 90%;
}

.feature-images{
    position: absolute;
    height: 90%;
}

#arrows{
    transform: rotate(-20deg);
}

.feature{
    transition: transform .5s;
}

.feature:hover{
    transform: scale(1.05);
    z-index: 1;
}

@media (max-width: 767px){
    .abstract-features{
        padding-bottom: 0px;
    }

    .feature-heading{
        text-align: center;
    }

    .feature-desc{
        padding-bottom: 50px;
        text-align: center;
    }
}
#influencer-header{
    color: #DEDEE0;
    text-align: center;
    font-size: 60px;
    padding-bottom: 50px;
}

.center{
    text-align: center;
}

#influencers{
    padding-top: 50px;
    padding-bottom: 80px;
}

#influencer-features{
    color: #DEDEE0;
    font-size: 30px;
}

#influencer-features{
    border-left: 2px solid #DEDEE0;
    list-style-type: none;
    margin-bottom: 0px;
    padding-left: 40px;
    border-left: 2px solid #DEDEE0;
}

.vertical-center{
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}

#influencer-image{
    width: 94%;
    transition: transform .3s;
}

#influencer-image:hover{
    transform: scale(1.05);
}

@media (max-width: 767px){
    #influencer-desc{
        flex-direction: column-reverse;
    }

    #influencer-features{
        font-size: 20px;
        margin-bottom: 30px !important;
    }
}

#brands{
    background-color: #f2f2f2;
    padding-top: 60px;
    padding-bottom: 80px;
}

#brand-header{
    color: #252323;
    text-align: center;
    font-size: 60px;
    padding-bottom: 50px;
}

#brand-features{
    color: #252323;
    font-size: 30px;
}

#brand-features{
    border-right: 2px solid #252323;
    padding-right: 40px;
    text-align: left;
    list-style-type: none;
    padding-left: 0px;
}

#brand-abstract{
    padding-bottom: 0px;
    width: 100%;
}

#brand-image{
    width: 94%;
    transition: transform .3s;
    -webkit-mask-image: url(/static/media/mask.ffae2306.svg);
            mask-image: url(/static/media/mask.ffae2306.svg);
    -webkit-mask-size: cover;
            mask-size: cover;
}

#brand-image:hover{
    transform: scale(1.05);
}

@media (max-width: 767px){
    #brand-features{
        text-align: left !important;
        font-size: 20px;
        border-left: 2px solid #252323;
        border-right: 0px !important;
        padding-right: 0px !important;
        padding-left: 40px;
        margin-bottom: 30px !important;
    }

    #brand-desc{
        flex-direction: column;
    }

    ul#brand-features{
        padding-left: 40px;
    }
}
#contacts{
    padding-bottom: 80px;
    background-color: #f2f2f2;
    padding-top: 60px;
}

#contact-header{
    color: #DEDEE0;
    font-size: 60px;
    text-align: center;
    padding-bottom: 40px;
}

#contact-card{
    background-color: #252323;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    padding-top: 60px;
    padding-bottom: 60px;
}

.contact-image{
    width: 30%;
    color: #DEDEE0;
}

.contact-tab{
    text-align: center;
    transition: transform .3s;
}

.contact-tab:hover{
    text-decoration: none;
}

.contact-text{
    color: #DEDEE0;
    padding-top: 5px;
}

.contact-tab:hover{
    transform: scale(1.05);
}

@media (max-width: 767px){
    .contact-tab{
        padding-bottom: 10px;
    }
}
#footer{
    background-color: #252323;
    height: 40px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

#footer-text{
    text-align: center;
    color: #DEDEE0;
    margin: auto;
    line-height: 40px;
}

#logo{
    margin-right: 0;
    color: #90949e;
    font-size: 40px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0px;
    flex: 1 1;
    display: flex;
    justify-content: center;
}
