.intro{
    text-align: center;
    padding-top: 210px;
}

#welcome{
    color: #DEDEE0;
    font-size: 60px;
}

#waitlist{
    background-color: #252323;
    color: #0ACDFF;
    font-size: 24px;
    border: 2px solid #0ACDFF;
    transition: .5s;
    font-family: Roboto;
}

#waitlist:hover{
    background-color: #0ACDFF;
    color: #252323;
}

#subtitle{
    color: #90949e;
    padding-bottom: 10px;
}

.square{
    opacity: 0;
    position: absolute;
    animation: fade;
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    z-index: -1;
}

#square1{
    width: 150px;
    left: 20%;
    top: 10%;
    transform: rotate(10deg);
}

#square2{
    width: 120px;
    right: 15%;
    top: 40%;
    transform: rotate(10deg);
    animation-delay: 2s;
}

#square3{
    width: 140px;
    left: 20%;
    top: 50%;
    transform: rotate(-24deg);
    animation-delay: 3s;
}

#square4{
    width: 100px;
    left: 35%;
    top: 45%;
    transform: rotate(14deg);
    animation-delay: 4s;
}

#square5{
    width: 140px;
    right: 25%;
    top: 15%;
    transform: rotate(-5deg);
    animation-delay: 1s;
}

#text{
    color: #DEDEE0;
    font-size: 20px;
}

@keyframes fade {
    20% {opacity: 0}
    80% {opacity: 1}
}

@media (max-width: 576px){
    #square5{
        visibility: hidden;
    }
}

@media (max-width: 767px){
    #square1{
        width: 120px;
    }
    #square2{
        width: 80px;
    }
    #square3{
        width: 90px;
    }
    #square5{
        width: 100px;
    }
}

@media (max-width: 992px){
    #square4{
        visibility: hidden;
    }
}