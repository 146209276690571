@import url("https://fonts.googleapis.com/css?family=Montserrat|Roboto|Lato");

html{
    overflow-x:hidden
}

body{
    background-color: #252323 !important;
}

h1,h4,h5,p{
    font-family: Montserrat !important;
}

h2,h3,li{
    font-family: Roboto !important;
}

.inverted{
    transform: rotate(180deg);
}

#transition-1{
    padding-top: 210px;
    margin-bottom: -2px;
    width: 100vw;
}

#transition-2{
    margin-top: -2px;
}

#transition-3{
    margin-bottom: -2px;
}

.full{
    width: 100vw;
}

@media (max-width: 767px){
    .order1{
        order: 1;
    }

    .order2{
        order: 2;
    }
}

.center{
    text-align: center;
}