#brands{
    background-color: #f2f2f2;
    padding-top: 60px;
    padding-bottom: 80px;
}

#brand-header{
    color: #252323;
    text-align: center;
    font-size: 60px;
    padding-bottom: 50px;
}

#brand-features{
    color: #252323;
    font-size: 30px;
}

#brand-features{
    border-right: 2px solid #252323;
    padding-right: 40px;
    text-align: left;
    list-style-type: none;
    padding-left: 0px;
}

#brand-abstract{
    padding-bottom: 0px;
    width: 100%;
}

#brand-image{
    width: 94%;
    transition: transform .3s;
    mask-image: url("../assets/mask.svg");
    mask-size: cover;
}

#brand-image:hover{
    transform: scale(1.05);
}

@media (max-width: 767px){
    #brand-features{
        text-align: left !important;
        font-size: 20px;
        border-left: 2px solid #252323;
        border-right: 0px !important;
        padding-right: 0px !important;
        padding-left: 40px;
        margin-bottom: 30px !important;
    }

    #brand-desc{
        flex-direction: column;
    }

    ul#brand-features{
        padding-left: 40px;
    }
}